import React from "react"

import Button from "@material-ui/core/Button"

const PhraseSection: React.FC = () => {
  return (
    <section className="flex flex-col items-center bg-primary py-14">
      <div className="w-full max-w-5xl px-4">
        <div className="text-4xl font-black md:text-5xl text-secondary">
          <p>LEMONADEで</p>
          <span>リモートワークを</span>
          <span>効率化しませんか？</span>
        </div>

        <div className="mx-4 mt-10 md:mx-0 md:w-72 border-3 border-secondary">
          <Button
            className="w-full px-8 py-3 text-lg font-bold rounded-none text-secondary"
            variant="text"
            color="secondary"
            href="/trial"
          >
            無料でβ版を利用する
          </Button>
        </div>
      </div>
    </section>
  )
}

export default PhraseSection
