import React from "react"

import styles from "./FunctionsSection.module.css"
import AnalysisImage from "./images/AnalysisImage"
import CalendarImage from "./images/CalendarImage"
import LikeImage from "./images/LikeImage"
import OnlinePresentationImage from "./images/OnlinePresentationImage"
import PersonalDataImage from "./images/PersonalDataImage"
import QualityCheckImage from "./images/QualityCheckImage"
import RocketLaunchImage from "./images/RocketLaunchImage"
import UserStatusImage from "./images/UserStatusImage"

const FunctionsSection: React.FC = () => {
  return (
    <section className="flex flex-col items-center px-4 py-20 bg-white">
      <div className="flex flex-col items-center w-full max-w-5xl">
        <h2 className="text-3xl font-bold text-center text-secondary">
          <span className="inline highlighter">LEMONADEの機能</span>
        </h2>

        <div className="flex flex-wrap w-full mt-10">
          <div className={styles.function}>
            <CalendarImage />
            <h3>朝会・日報</h3>
            <div className={styles.description}>
              1日の計画を組み立て、その実績を記録します。
            </div>
          </div>
          <div className={styles.function}>
            <QualityCheckImage />
            <h3>進捗報告</h3>
            <div className={styles.description}>
              定期的な情報共有を行い、チームの状況を常に意識します。
            </div>
          </div>
          <div className={styles.function}>
            <UserStatusImage />
            <h3>1 on 1</h3>
            <div className={styles.description}>
              事前にアジェンダを作成し、実りあるコミュニケーションを支援します。
            </div>
          </div>
          <div className={styles.function}>
            <PersonalDataImage />
            <h3>パルスサーベイ</h3>
            <div className={styles.description}>
              悩みや不満、企業文化に関する調査で、組織の課題を早期発見します。
            </div>
          </div>
          <div className={styles.function}>
            <OnlinePresentationImage />
            <h3>フィードバック</h3>
            <div className={styles.description}>
              メンバー間のフィードバックを共有し、チームの成長を促します。
            </div>
          </div>
          <div className={styles.function}>
            <RocketLaunchImage />
            <h3>OKR</h3>
            <div className={styles.description}>
              目標設定で、業務認識のズレを回避し、チームの連携を促します。
            </div>
          </div>
          <div className={styles.function}>
            <AnalysisImage />
            <h3>分析・レポート</h3>
            <div className={styles.description}>
              目標設定の達成率やチームへの貢献度を定量的に見える化します。
            </div>
          </div>
          <div className={styles.function}>
            <LikeImage />
            <h3>称賛</h3>
            <div className={styles.description}>
              メンバー間で称賛を送りあい、自己効力感と信頼関係を育みます。
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FunctionsSection
