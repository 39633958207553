import { Link } from "gatsby"
import React from "react"

import Button from "@material-ui/core/Button"

import ScreenImage from "./images/ScreenImage"

const EyecatchSection: React.FC = () => {
  const scroll = () => {
    document
      .getElementById("download")
      ?.scrollIntoView({ behavior: "smooth", block: "center" })
  }

  return (
    <section className="flex flex-col items-center pb-12 bg-primary md:pb-24">
      <div className="w-full max-w-5xl px-4">
        <header className="flex items-center justify-between py-4">
          <Link className="text-2xl font-bold text-secondary font-logo" to="/">
            <h1>LEMONADE</h1>
          </Link>
          <div className="hidden md:flex">
            <div className="w-48 border-2 border-secondary bg-secondary">
              <Button
                className="w-full py-2 font-bold text-white rounded-none"
                variant="contained"
                color="secondary"
                onClick={scroll}
              >
                資料ダウンロード
              </Button>
            </div>
            <div className="w-48 ml-4 border-2 border-secondary">
              <Button
                className="w-full py-2 font-bold rounded-none text-secondary"
                variant="text"
                color="secondary"
                href="/trial"
              >
                β版事前登録
              </Button>
            </div>
          </div>
        </header>

        <div className="md:relative md:mt-28 md:h-152 lg:h-104">
          <div className="max-w-2xl py-6 pr-0 md:pr-8">
            <span className="px-4 py-4 text-3xl font-black bg-white md:text-5xl text-secondary md:text-center">
              <span>リモートワークを加速する</span>
            </span>
            <div className="mt-6 font-bold text-md md:text-xl md:mt-14 text-secondary">
              <span>進捗管理から日報、</span>
              <span>チームビルディングまで</span>
            </div>
            <div className="text-2xl font-bold md:mt-4 md:text-3xl text-secondary">
              <span>最高のパフォーマンスを発揮する</span>
              <span>リモートワーク運用がこれ一つ</span>
            </div>
          </div>

          <div className="max-w-3xl p-4 -mx-6 md:w-full md:mx-0 md:p-0 md:absolute md:bottom-0 md:-right-96">
            <ScreenImage />
          </div>

          <div className="flex flex-col items-center w-full px-8 py-8 md:items-start md:absolute md:bottom-0 md:left-0 md:px-0">
            <div className="w-full max-w-xs border-3 border-secondary bg-secondary">
              <Button
                className="w-full py-3 text-lg font-bold text-white rounded-none"
                variant="contained"
                color="secondary"
                onClick={scroll}
              >
                資料ダウンロード
              </Button>
            </div>
            <div className="w-full max-w-xs mt-4 md:hidden border-3 border-secondary">
              <Button
                className="w-full py-3 text-lg font-bold rounded-none text-secondary"
                variant="text"
                color="secondary"
                href="/trial"
              >
                β版事前登録
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default EyecatchSection
