import { navigate } from "gatsby"
import React, { useState } from "react"

import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"

import * as analytics from "analytics"

const DownloadSection: React.FC = () => {
  const [success, setSuccess] = useState(false)

  const requestDocument = async () => {
    analytics.requestDocument()
    setSuccess(true)
  }

  return (
    <section
      id="download"
      className="flex flex-col items-center py-14 bg-background-color"
    >
      <div className="flex flex-col items-center w-full max-w-5xl px-4">
        <h2 className="mb-8 text-3xl font-bold text-center text-secondary">
          <span className="highlighter">無料で資料を</span>
          <span className="highlighter">ダウンロードする</span>
        </h2>

        <iframe
          className="hidden"
          name="dummy-form"
          onLoad={() => {
            if (success) {
              navigate("/confirm?request=document")
            }
          }}
        />
        <form
          className="flex flex-col items-center w-full mt-8"
          action="https://docs.google.com/forms/u/0/d/e/1FAIpQLScZQikGaB-c-I3jq3XizGlsebWwqU1NFhTB60t45Bz42mn1fw/formResponse"
          method="post"
          target="dummy-form"
          onSubmit={requestDocument}
        >
          <input
            className="hidden"
            name="entry.746390085"
            readOnly
            value="LEMONADE"
          />
          <div className="w-full mb-2 text-sm text-secondary">
            氏名 <span className="text-xs text-yellow">※ 必須</span>
          </div>
          <TextField
            className="w-full mb-4 bg-white"
            variant="outlined"
            required
            name="entry.772014860"
          />
          <div className="w-full mb-2 text-sm text-secondary">
            会社名 <span className="text-xs text-yellow">※ 必須</span>
          </div>
          <TextField
            className="w-full mb-4 bg-white"
            variant="outlined"
            required
            name="entry.295393504"
          />
          <div className="w-full mb-2 text-sm text-secondary">
            メールアドレス <span className="text-xs text-yellow">※ 必須</span>
          </div>
          <TextField
            className="w-full mb-4 bg-white"
            variant="outlined"
            type="email"
            required
            name="emailAddress"
          />
          <div className="w-full mb-2 text-sm text-secondary">
            電話番号 <span className="text-xs text-yellow">※ 必須</span>
          </div>
          <TextField
            className="w-full mb-4 bg-white"
            variant="outlined"
            type="phone"
            required
            name="entry.141874093"
          />
          <div className="w-full mb-2 text-sm text-secondary">
            ご質問・ご要望
          </div>
          <TextField
            className="w-full mb-12 bg-white"
            variant="outlined"
            multiline
            rows={3}
            rowsMax={9}
            name="entry.1273354336"
          />
          <Button
            className="px-16 py-4 text-lg font-bold rounded-none w-max text-secondary"
            variant="contained"
            color="primary"
            type="submit"
            disabled={success}
          >
            資料ダウンロード
          </Button>
        </form>
      </div>
    </section>
  )
}

export default DownloadSection
