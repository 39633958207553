import React from "react"

import Footer from "components/layout/Footer"

import DescriptionSection from "./DescriptionSection"
import DownloadSection from "./DownloadSection"
import EyecatchSection from "./EyecatchSection"
import FeaturesSection from "./FeaturesSection"
import FunctionsSection from "./FunctionsSection"
import IntegrationsSection from "./IntegratinsSection"
import ObjectivesSection from "./ObjectivesSection"
import PhraseSection from "./PhraseSection"

const LandingContainer: React.FC = () => {
  return (
    <>
      <EyecatchSection />
      <DescriptionSection />
      <FeaturesSection />
      <ObjectivesSection />
      <FunctionsSection />
      <IntegrationsSection />
      <PhraseSection />
      <DownloadSection />
      <Footer />
    </>
  )
}

export default LandingContainer
