import { useStaticQuery, graphql } from "gatsby"
import React from "react"

import Img from "gatsby-image"

const TeamWorkImage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "images/landing/team_work.png" }
      ) {
        childImageSharp {
          fixed(height: 180) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return <Img fixed={data.placeholderImage.childImageSharp.fixed} />
}

export default TeamWorkImage
