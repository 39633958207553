import React, { useEffect } from "react"

import * as analytics from "analytics"
import LandingContainer from "components/landing/LandingContainer"
import SEO from "components/seo"

const IndexPage: React.FC = () => {
  useEffect(() => {
    analytics.viewLP()
  }, [])

  return (
    <>
      <SEO />
      <LandingContainer />
    </>
  )
}

export default IndexPage
