import { useStaticQuery, graphql } from "gatsby"
import React from "react"

import Img from "gatsby-image"

const ScreenImage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "images/landing/screen.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img fluid={data.placeholderImage.childImageSharp.fluid} alt="Screen" />
  )
}

export default ScreenImage
