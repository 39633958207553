import React from "react"

import VideoCallImage from "./images/VideoCallImage"

const DescriptionSection: React.FC = () => {
  return (
    <section className="flex flex-col items-center px-4 py-20 bg-white">
      <div className="flex flex-col items-center w-full max-w-5xl">
        <h2 className="text-3xl font-bold text-center text-secondary">
          <span className="inline highlighter">LEMONADEとは？</span>
        </h2>
        <div className="mt-10 text-2xl font-bold text-center md:tracking-wider md:leading-12 md:mt-12 md:text-4xl text-secondary">
          <span>リモートワーク運用に</span>
          <span>必要な業務を</span>
          <span>自動化・効率化し</span>
          <span>最大限の生産性を</span>
          <span>発揮する</span>
          <span>リモートチームを</span>
          <span>構築します</span>
        </div>

        <div className="flex flex-col mt-12 md:flex-row-reverse">
          <div className="w-full mb-10 md:w-1/2 md:mb-0">
            <VideoCallImage />
          </div>
          <div className="w-full text-lg md:px-4 md:w-1/2 md:font-bold text-secondary">
            <p>
              「リモートワーク」は柔軟な働き方で高い生産性が期待できる一方で、進捗管理・コミュニケーションの難しさや社員への高い自立性・自己管理能力の要求など、リモートチーム特有の課題に向き合わなければなりません。
            </p>
            <p className="mt-4">
              LEMONADEは、毎日の目標設定や状況報告など、リモートワークに必要な運用を「ルーティン」化することで、分散されたチームの効率的な状況把握と定量的なパフォーマンスの分析を実現し、リモートチームを最高のチームへ導きます。
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DescriptionSection
