import React from "react"

import styles from "./ObjectivesSection.module.css"
import AnalyticsScreenImage from "./images/AnalyticsScreenImage"
import CheckinScreenImage from "./images/CheckinScreenImage"
import RelationsScreenImage from "./images/RelationsScreenImage"
import RoutineScreenImage from "./images/RoutineScreenImage"

const ObjectivesSection: React.FC = () => {
  return (
    <section className="flex flex-col items-center py-20 bg-background-color">
      <div className="w-full max-w-5xl px-4">
        <h2 className="mb-10 text-2xl font-bold text-center text-secondary">
          <span>LEMONADEを</span>
          <span>ご利用いただくことで、</span>
          <span>以下を実現します</span>
        </h2>

        <div className={styles.card}>
          <div className={styles.phrase}>
            <span>メンバーが</span>
            <span>最高のパフォーマンスを</span>
            <span>発揮できる働き方を。</span>
          </div>
          <h3>
            <span className="inline highlighter">
              <span> フレキシブルな</span>
              <span>チェックインと</span>
              <span>朝会の自動化</span>
            </span>
          </h3>
          <div className={styles.description}>
            <span>
              時間や場所をフレキシブルに活用できるよう、LEMONADEで業務の開始や作業場所、1日の目標を共有します。
            </span>
            <span>
              一人ひとりが最高のパフォーマンスで仕事ができるタイムゾーン・場所からのチーム連携をサポートします。
            </span>
          </div>
        </div>

        <div className="flex flex-col mt-10 md:flex-row md:mt-14">
          <div className={styles.object}>
            <div className={styles.number}>01</div>
            <h4>
              <span>一日の業務の</span>
              <span>チェックイン</span>
            </h4>
            <div className={styles.description}>
              朝のジョギングの後、カフェのオープン時間にあわせて、あなたの好きな場所・時間でLEMONADEにチェックイン。メンバーに今日の目標と予定を共有して、1日の業務を始めましょう。
            </div>
          </div>
          <div className="flex-grow mt-4">
            <CheckinScreenImage />
          </div>
        </div>

        <div className="flex flex-col mt-10 mb-16 md:flex-row-reverse md:mt-14">
          <div className={styles.object}>
            <div className={styles.number}>02</div>
            <h4>
              <span>毎日の朝会を</span>
              <span>ルーティンで</span>
              <span>自動化</span>
            </h4>
            <div className={styles.description}>
              質問に答えるだけで、簡単に情報・認識共有ができます。朝会に全員が集まるのを待つ必要はなく、文章化・記録することで曖昧さを回避したり、日々の変化をレポートで確認することができます。
            </div>
          </div>
          <div className="flex-grow mt-4">
            <RoutineScreenImage />
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.phrase}>
            <span>チームの分析で</span>
            <span>チーム構築の</span>
            <span>PDCAサイクルを高速化。</span>
          </div>
          <h3>
            <span className="inline highlighter">
              <span>成果を出す</span>
              <span>リモートチーム</span>
              <span>の</span>
              <span>定量的な</span>
              <span>マネジメント</span>
            </span>
          </h3>
          <div className={styles.description}>
            <span>
              チームのパフォーマンスやコンディションに関するデータを集計・分析し、定量的なレポートを簡単に取得できます。
            </span>
            <span>
              LEMONADEはチームの課題を早期発見し、改善サイクルの高速化を実現します。
            </span>
          </div>
        </div>

        <div className="flex flex-col mt-10 md:flex-row md:mt-14">
          <div className={styles.object}>
            <div className={styles.number}>03</div>
            <h4>
              <span>定量的なマネジメントで</span>
              <span>チームを分析</span>
            </h4>
            <div className={styles.description}>
              ルーティンで収集した個々のアウトプットをレポートに。個々の目標達成率やモチベーションの変化でパフォーマンスを見える化します。
            </div>
          </div>
          <div className="flex-grow mt-4">
            <AnalyticsScreenImage />
          </div>
        </div>

        <div className="flex flex-col mt-10 md:flex-row-reverse md:mt-14">
          <div className={styles.object}>
            <div className={styles.number}>04</div>
            <h4>
              <span>チームの</span>
              <span>心理的安全性</span>
              <span>を確保</span>
            </h4>
            <div className={styles.description}>
              孤独になりがちなリモートワーク環境においては、「率直に声を挙げ、表現できる安心感」が担保された心理的安全性のある環境が不可欠です。
              LEMONADEは、ルーティンを通じて、個々の成果のアピールや、他者への感謝・称賛をチームへ共有。見える化することで、チームメンバーの自己効力感と信頼関係を育みます。
            </div>
          </div>
          <div className="flex-grow mt-4">
            <RelationsScreenImage />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ObjectivesSection
