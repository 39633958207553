import React from "react"

import AsanaLogo from "assets/images/integrations/asana.svg"
import GithubLogo from "assets/images/integrations/github.svg"
import GoogleCalendarLogo from "assets/images/integrations/google_calendar.svg"
import OutlookLogo from "assets/images/integrations/outlook.svg"
import TodoistLogo from "assets/images/integrations/todoist.svg"
import TrelloLogo from "assets/images/integrations/trello.svg"

import styles from "./IntegrationsSection.module.css"

const IntegrationsSection: React.FC = () => {
  return (
    <section className="flex flex-col items-center px-4 py-20 bg-background-color">
      <div className="flex flex-col items-center w-full max-w-5xl">
        <h2 className="mb-10 text-3xl font-bold text-center text-secondary">
          <span className="inline highlighter">
            <span>普段お使いの</span>
            <span>各サービスと</span>
            <span>連携可能</span>
          </span>
        </h2>

        <div className="flex flex-wrap w-full">
          <div className={styles.item}>
            <img src={AsanaLogo} />
            <div>Asana</div>
          </div>
          <div className={styles.item}>
            <img src={TrelloLogo} />
            <div>Trello</div>
          </div>
          <div className={styles.item}>
            <img src={TodoistLogo} />
            <div>Todoist</div>
          </div>
          <div className={styles.item}>
            <img src={GithubLogo} />
            <div>GitHub</div>
          </div>
          <div className={styles.item}>
            <img src={OutlookLogo} />
            <div>Outlook</div>
          </div>
          <div className={styles.item}>
            <img src={GoogleCalendarLogo} />
            <div>Google Calendar</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default IntegrationsSection
