import React from "react"

import styles from "./FeaturesSection.module.css"
import AnalyticsProcessImage from "./images/AnalyticsProcessImage"
import InformationFlowImage from "./images/InformationFlowImage"
import TeamWorkImage from "./images/TeamWorkImage"

const FeaturesSection: React.FC = () => {
  return (
    <section className="flex flex-col items-center py-20 bg-primary">
      <div className="flex flex-col items-center w-full max-w-5xl">
        <h2 className="text-3xl font-bold text-center text-secondary">
          <span className="inline highlighter to-white">LEMONADEの特徴</span>
        </h2>

        <div className="flex flex-col w-full px-4 mt-10 md:px-2 md:flex-row">
          <div className="w-full pb-4 md:w-1/3 md:px-2">
            <div className={styles.card}>
              <h3>
                <p>仕事のルーティンを</p>
                <p>カンタン自動化</p>
              </h3>
              <div className={styles.image}>
                <InformationFlowImage />
              </div>
              <div className={styles.description}>
                <span>
                  設定した時間と内容でLEMONADEが一人ひとりに自動で質問。
                </span>
                <span>
                  リモートワークのフレキシブルさを尊重しながら、メリハリのある働き方を支援します。
                </span>
              </div>
            </div>
          </div>

          <div className="w-full pb-4 md:w-1/3 md:px-2">
            <div className={styles.card}>
              <h3>
                <p>チームの仕事状況を</p>
                <p>見える化</p>
              </h3>
              <div className={styles.image}>
                <AnalyticsProcessImage />
              </div>
              <div className={styles.description}>
                <span>一人ひとりの目標設定や進捗状況を共有して常に把握。</span>
                <span>
                  生産性や心身面に関するデータを日々集計し、レポートを自動作成します。
                </span>
              </div>
            </div>
          </div>

          <div className="w-full pb-4 md:w-1/3 md:px-2">
            <div className={styles.card}>
              <h3>
                <p>リモートチーム運用を</p>
                <p>強力サポート</p>
              </h3>
              <div className={styles.image}>
                <TeamWorkImage />
              </div>
              <div className={styles.description}>
                <span>
                  朝会・日報をはじめ、1on1や意識調査など、ルーティンのテンプレートが充実。
                </span>
                <span>
                  最高のパフォーマンスを発揮するチーム運用をサポートします。
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturesSection
